document.addEventListener('livewire:init', () => {
    Livewire.on('app:scroll-to', (event) => {
        // Determine the field id
        const selector = event?.query
        if (!selector) {
            return
        }

        // Find the element by its labels
        let element = document.querySelector('label[for="' + selector + '"]')
        // Or fallback to the element itself (i.e. radio buttons)
        if (!element) {
            element = document.querySelector('[name="' + selector + '"]')
        }

        // Without an element, we're lost
        if (!element) {
            return
        }

        try {
            // Scroll the element into view
            element.scrollIntoView({
                behavior: 'instant', // Must be instant as otherwise the scroll margin won't work
            })
        } catch {
        }

        const headerHeight = document.getElementById('navbarwrapper').offsetHeight
        const scrollMargin = 20

        // Add a margin as we don't want the element to be directly to the header
        window.scrollBy(0, (headerHeight + scrollMargin) * -1)
    });
});
