import './bootstrap';
import './toggle';
import './accordion';
import './anchor';
import './navbarHeight';
import './FieldToggle';
import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';
import AOS from 'aos';
import DatePicker from '@/DatePicker';
import FieldToggle from '@/FieldToggle';
import SelectText from '@/SelectText';
import Chart from '@/Chart';
import * as tinymce from './editor';
import './livewireValidation';
import './livewireRebind';

window.Alpine = Alpine;
window.Livewire = Livewire;
window.tinymce = tinymce;

AOS.init({
    duration: 700
});

document.addEventListener('livewire:initialized', function () {
    FieldToggle.initAll('[data-toggle="field-toggle"]');
    DatePicker.initAll('[data-toggle="date-picker"]');
    SelectText.initAll('[data-toggle="select-text"]');
    Chart.initAll('[data-chart]');
});

Livewire.start();
