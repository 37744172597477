import DatePicker from "@/DatePicker";

document.addEventListener('livewire:init', () => {
    window.Livewire.hook('component.init', ({ component }) => {
        // As the DOM has changed, let's bind the datepicker on the new elements too
        Alpine.nextTick(() => {
            DatePicker.initAll('[data-toggle="date-picker"]');
        });
    })

    window.Livewire.hook('morph.updated', ({ component }) => {
        // As the DOM has changed, let's bind the datepicker on the new elements too
        Alpine.nextTick(() => {
            DatePicker.initAll('[data-toggle="date-picker"]');
        });
    })
});
